<template>
  <div class="search">
    <Nav></Nav>
    <div class="search-content">
      <div class="search-info">
        <div class="keyWord">
          <span>关键字：</span>
          <el-input v-model="searchInfo.keyword"></el-input>
          <el-button @click="onSearch">搜索</el-button>
          <span v-if="heightSearch === false"
            >您尚未登录，登录后可使用高级搜索</span
          >
        </div>
        <div class="pic-count">
          <span>首页</span> > 简单搜索 关键字：{{ searchInfo.keyword }} 共找到{{
            postCount
          }}篇稿件
        </div>
      </div>
      <div class="search-page">
        <div class="search-height" v-if="heightSearch">
          <div class="keyword">
            <span>关键字：</span>
            <el-input v-model="searchInfo.keyword"></el-input>
          </div>
          <div class="keyword-select">
            <span>搜索范围：</span>
            <el-checkbox v-model="searchInfo.keyInMain"
              >搜索稿件正文</el-checkbox
            >
            <el-checkbox v-model="searchInfo.keyInPic"
              >搜索图片说明</el-checkbox
            >
          </div>
          <div class="author-con">
            <div class="author">
              <div class="author-search">署名：</div>
              <el-input
                placeholder="请输入作者"
                v-model="searchInfo.author"
                clearable
              ></el-input>
            </div>
          </div>
          <!-- <div class="search-store">
            <div class="store">所属库：</div>
            <div class="store-list">
              <el-checkbox-group v-model="storeIds" @change="postStore">
                <el-checkbox
                  v-for="searcgStore in canserachStore"
                  :key="searcgStore.id"
                  :label="searcgStore.id"
                  >{{ searcgStore.name }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div> -->
          <div class="input-line">
            <div class="event">
              <div class="event-name">发生时间：</div>
              <div class="input-line-content">
                <el-date-picker
                  v-model="searchInfo.eventFrom"
                  type="date"
                  placeholder="选择发生开始日期"
                  :editable="false"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                ></el-date-picker
                >-
                <el-date-picker
                  v-model="searchInfo.eventTo"
                  type="date"
                  placeholder="选择发生结束日期"
                  :editable="false"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                  @keyup.enter.native="onSearch"
                ></el-date-picker>
              </div>
            </div>
          </div>
          <div class="input-line">
            <div class="innerEdit">
              <div class="innertime-name">上传时间：</div>
              <div class="input-line-content">
                <el-date-picker
                  v-model="searchInfo.uploadFrom"
                  type="date"
                  placeholder="选择发生开始日期"
                  :editable="false"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                ></el-date-picker
                >-
                <el-date-picker
                  v-model="searchInfo.uploadTo"
                  type="date"
                  placeholder="选择发生结束日期"
                  :editable="false"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                  @keyup.enter.native="onSearch"
                ></el-date-picker>
              </div>
            </div>
          </div>
          <div class="input-line">
            <div class="addr">
              <div class="addr-name">检索地址：</div>
              <div class="input-line-content">
                <el-cascader
                  :options="address"
                  :props="addr_picker_props"
                  width="320"
                  filterable
                  clearable
                  change-on-select
                  separator=">"
                  v-model="addr_picker_options"
                  @keyup.enter.native="onSearch"
                ></el-cascader>
              </div>
            </div>
          </div>
          <div class="input-line">
            <div class="type">
              <div class="type-name">检索类型：</div>
              <div class="input-line-content">
                <el-cascader
                  :options="types"
                  width="320"
                  :props="types_picker_props"
                  filterable
                  change-on-select
                  clearable
                  separator=">"
                  v-model="type_picker_options"
                  @keyup.enter.native="onSearch"
                ></el-cascader>
              </div>
            </div>
          </div>
          <div class="search-btn">
            <div class="btn">
              <el-button type="primary" @click="onSearch">搜索</el-button>
              <el-button type="primary" @click="onClear">清空</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="page-container">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="20"
          :hide-on-single-page="true"
          layout="prev, pager, next, jumper"
          :total="downTotal"
        ></el-pagination>
      </div>
      <div class="search-con">
        <div class="post-list">
          <div
            class="post-box"
            v-for="(k, index) in postList"
            :key="k.id"
            @click="onLookPost(k.id)"
          >
            <div class="part2">
              <img :src="k.imageURL" alt="" />
            </div>
            <div class="part3">
              <div class="title">{{ k.title }}</div>
              <div class="addr">{{ k.eventAddrNamePath }}</div>
              <div class="text">{{ k.mainText }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-container">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="20"
          :hide-on-single-page="true"
          layout="prev, pager, next, jumper"
          :total="downTotal"
        ></el-pagination>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: { Nav, Bottom },
  name: "Index",
  data: function () {
    return {
      page: 1,
      currentPage: 1,
      downTotal: 1,
      postList: [],
      postCount: "", // 稿件数
      searchInfo: {
        keyword: "",
        order: "0",
        keyInMain: true,
      },
      heightSearch: false, // 高级搜索
      storeIds: [1],
      addr_picker_props: {
        value: "id",
        label: "name",
        children: "subs",
        checkStrictly: true,
      },
      addr_picker_options: [], // 默认地址
      type_picker_options: [], // 默认地址
      types_picker_props: {
        value: "id",
        label: "name",
        children: "subs",
        checkStrictly: true,
      },
      address: [],
      types: [],
      canserachStore: [], // 能够搜索的库
    };
  },
  created() {
    this.reload();
    this.syncfresh();
  },
  beforeRouteUpdate(to, from, next) {
    // if (to.fullPath != from.fullPath) {
    //   next();
    //   this.reload();
    // }
    if (to.path == from.path) {
      console.log(to);
      this.searchInfo.keyword = to.query.keyWord;
      // this.reload();
      this.onSearch();
      return false;
    }
  },
  methods: {
    reload() {
      var thiz = this;
      if (this.$route.query.keyWord != "") {
        thiz.filter();
      }
      RD.pure()
        .user()
        .sync()
        .then((data) => {
          thiz.canserachStore = data.searchStores;
        });

      RD.addr()
        .tree()
        .then((data) => {
          thiz.address = data;
        });
      RD.type()
        .tree()
        .then((data) => {
          thiz.types = data;
        });
    },
    filter() {
      var thiz = this;
      console.log("453", thiz.$route.query);
      thiz.$route.query.keyWord = thiz.$route.query.keyWord.replace("<", "");
      thiz.$route.query.keyWord = thiz.$route.query.keyWord.replace(">", "");
      thiz.$route.query.keyWord = thiz.$route.query.keyWord.replace("/", "");
      thiz.$route.query.keyWord = thiz.$route.query.keyWord.replace("(", "");
      thiz.$route.query.keyWord = thiz.$route.query.keyWord.replace(")", "");
      thiz.$route.query.keyWord = thiz.$route.query.keyWord.replace("{", "");
      thiz.$route.query.keyWord = thiz.$route.query.keyWord.replace("}", "");
      thiz.$route.query.keyWord = thiz.$route.query.keyWord.replace(";", "");
      console.log("8999", thiz.$route.query.keyWord);
      thiz.searchInfo.keyword = thiz.$route.query.keyWord;
      thiz.onSearch();
    },
    // sync刷新
    syncfresh() {
      var thiz = this;
      RD.pure()
        .user()
        .sync()
        .then((data) => {
          thiz.heightSearch = true;
        })
        .catch(() => {
          thiz.heightSearch = false;
        });
    },
    handleCurrentChange(val) {
      var thiz = this;
      thiz.page = val;
      thiz.currentPage = val;
      thiz.onSearch(thiz.page);
    },
    onSearchButton() {
      console.log("op", this.searchInfo.keyword);
      this.$router.push({
        path: "/search.html",
        query: { keyWord: this.searchInfo.keyword },
      });
    },
    onSearch() {
      var thiz = this;
      thiz.postStore();

      // 地址
      if (thiz.addr_picker_options.length !== 0) {
        thiz.searchInfo.addrId =
          thiz.addr_picker_options[thiz.addr_picker_options.length - 1];
      }
      // 类型
      if (thiz.type_picker_options.length !== 0) {
        thiz.searchInfo.typeId =
          thiz.type_picker_options[thiz.type_picker_options.length - 1];
      }
      if (thiz.searchInfo.storeIds == undefined) {
        thiz.$message("必须勾选至少一个库");
      } else {
        RD.query()
          .query(thiz.searchInfo, thiz.page, 20)
          .then((data) => {
            console.log("sdss", data);
            data.list.forEach((i) => {
              i.imageURL = RD.pic().id(i.frontPicId).mImage_URL();
              i.eventAddrNamePath = i.eventAddrNamePath.join(">>");
            });
            thiz.postList = data.list;
            thiz.postCount = data.pager.total;
            thiz.currentPage = data.pager.currentPage;
            thiz.downTotal = data.pager.total;
          });
      }
    },
    // 库选择
    postStore() {
      this.searchInfo.storeIds = [];
      this.storeIds.forEach((value) => {
        this.searchInfo.storeIds.push(parseInt(value));
      });
    },
    // 查看稿件
    onLookPost(postId) {
      this.$router.push({
        path: "/post.html",
        query: { id: postId },
      });
    },
    // 清空
    onClear() {
      this.searchInfo = {};
    },
  },
};
</script>

<style scoped lang="less">
.search {
  height: auto;
  background-color: #fff;
}
@media all and (max-width: 800px) {
  .search-content {
    width: 750px;
    margin: 1rem auto;
    .search-info {
      height: auto;
      font-size: 1.3rem;
      .keyWord {
        margin: 1rem 0;
        .el-input {
          width: 20rem;
        }
        .el-button {
          margin-left: 10px;
        }
        span {
          margin-left: 10px;
        }
      }
    }
    .pic-count {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #dfdfdf;
      text-align: left;
      span {
        color: red;
      }
    }
    .search-page {
      display: felx;
      position: relative;
      font-size: 1.3rem;
      font-weight: bold;
      background-color: #efefef;
      .search-height {
        margin-top: 10px;
        width: 780px;
        margin: 0 auto;
        .keyword {
          text-align: left;
          font-size: 1.6rem;
          span {
            display: inline-block;
            width: 80px;
            text-align: right;
          }
          .el-input {
            width: 220px;
          }
        }
        .keyword-select {
          text-align: left;
          font-size: 1.6rem;
          height: 4rem;
          line-height: 4rem;
        }
        .search-store {
          margin-top: 10px;
          display: flex;
          flex-wrap: wrap;
          font-size: 1.6rem;
          text-align: left;
          .store {
            width: 8%;
            text-align: left;
          }
          .store-list {
            margin: 0px;
            .el-checkbox-group {
              display: flex;
              flex-wrap: wrap;
              .el-checkbox {
                width: 80px;
              }
            }
          }
        }
        .search-btn {
          text-align: center;
          position: relative;
          height: 40px;
          padding-bottom: 20px;
          .btn {
            position: absolute;
            left: 80px;
            top: 0px;
          }
        }
        .input-line {
          text-align: left;
          margin: 5px 0;
          padding: 0px;
          display: flex;
          .addr,
          .type {
            display: flex;
            margin-right: 10px;
            .addr-name,
            .type-name {
              width: 27%;
              height: 40px;
              line-height: 40px;
              font-size: 1.6rem;
            }
            .input-line-content {
              width: 70%;
            }
          }
          .event,
          .innerEdit {
            display: flex;
            .event-name,
            .innertime-name {
              height: 50px;
              line-height: 50px;
              font-size: 1.6rem;
            }
            .input-line-content {
              margin-top: 5px;
            }
          }
        }
        .author-con {
          display: flex;
          height: 40px;
          position: relative;
          .author {
            display: flex;
            text-align: right;
            font-size: 1.6rem;
            .author-search {
              height: 40px;
              width: 77px;
              line-height: 40px;
            }
          }
          .el-input {
            width: 220px;
          }
          .keyword-select {
            height: 50px;
            line-height: 50px;
            margin-left: 30px;
          }
        }
      }
      .page-container {
        position: absolute;
        right: 20px;
        top: 0px;
      }
    }
    .search-con {
      display: flex;
      .post-list {
        width: 100%;
        .post-box {
          height: 200px;
          display: flex;
          font-size: 1.3rem;
          margin: 10px auto;
          cursor: pointer;
          .part2 {
            width: 250px;
            height: 200px;
            div {
              height: 100%;
              width: 100%;
              background: no-repeat 50% / contain;
            }
            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
              background: #ccc;
            }
          }
          .part3 {
            flex: 2;
            margin-left: 30px;
            .title {
              font-size: 18px;
            }
            .addr {
              text-align: left;
              height: 50px;
              line-height: 50px;
              font-size: 14px;
              font-weight: 400;
              font-family: "Microsoft Yahei", "Simsun";
            }
            div {
              text-align: left;
              height: 30px;
              line-height: 30px;
            }
            .text {
              height: 110px;
              font-size: 14px;
              font-weight: 400;
              font-family: "Microsoft Yahei", "Simsun";
              overflow-y: auto;
              // overflow: hidden;
              // display: -webkit-box;
              // -webkit-line-clamp: 4;
              // -webkit-box-orient: vertical;
              // text-overflow: ellipsis;
              // white-space: normal;
            }
          }
          .part4 {
            width: 555px;
            border-right: 1px solid #ccc;
            .title {
              text-align: center;
              height: 50px;
              line-height: 50px;
              border-bottom: 1px solid #ccc;
            }
            .four {
              height: 199px;
              overflow-y: auto;
              .four-title {
                text-align: left;
                margin-left: 5px;
                margin-bottom: 10px;
              }
              .con {
                text-align: left;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
    .page-container {
      text-align: right;
      margin-top: 5px;
    }
  }
}
@media all and (min-width: 801px) {
  .search-content {
    width: 120rem;
    margin: 1rem auto;
    .search-info {
      height: auto;
      font-size: 1.6rem;
      .keyWord {
        margin: 1rem 0;
        .el-input {
          width: 27rem;
        }
        .el-button {
          margin-left: 10px;
        }
        span {
          margin-left: 10px;
        }
      }
    }
    .pic-count {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #dfdfdf;
      text-align: left;
      span {
        color: red;
      }
    }
    .search-page {
      display: felx;
      position: relative;
      font-size: 1.3rem;
      font-weight: bold;
      background-color: #efefef;
      .search-height {
        margin-top: 10px;
        width: 780px;
        margin: 0 auto;
        .keyword {
          text-align: left;
          font-size: 1.6rem;
          span {
            display: inline-block;
            width: 80px;
            text-align: right;
          }
          .el-input {
            width: 220px;
          }
        }
        .keyword-select {
          text-align: left;
          font-size: 1.6rem;
          height: 4rem;
          line-height: 4rem;
        }
        .search-store {
          margin-top: 10px;
          display: flex;
          flex-wrap: wrap;
          font-size: 1.6rem;
          text-align: left;
          .store {
            width: 8%;
            text-align: left;
          }
          .store-list {
            margin: 0px;
            .el-checkbox-group {
              display: flex;
              flex-wrap: wrap;
              .el-checkbox {
                width: 80px;
              }
            }
          }
        }
        .search-btn {
          text-align: center;
          position: relative;
          height: 40px;
          padding-bottom: 20px;
          .btn {
            position: absolute;
            left: 80px;
            top: 0px;
          }
        }
        .input-line {
          text-align: left;
          margin: 5px 0;
          padding: 0px;
          display: flex;
          .addr,
          .type {
            display: flex;
            margin-right: 10px;
            .addr-name,
            .type-name {
              width: 27%;
              height: 40px;
              line-height: 40px;
              font-size: 1.6rem;
            }
            .input-line-content {
              width: 70%;
            }
          }
          .event,
          .innerEdit {
            display: flex;
            .event-name,
            .innertime-name {
              height: 50px;
              line-height: 50px;
              font-size: 1.6rem;
            }
            .input-line-content {
              margin-top: 5px;
            }
          }
        }
        .author-con {
          display: flex;
          height: 40px;
          position: relative;
          .author {
            display: flex;
            text-align: right;
            font-size: 1.6rem;
            .author-search {
              height: 40px;
              width: 77px;
              line-height: 40px;
            }
          }
          .el-input {
            width: 220px;
          }
          .keyword-select {
            height: 50px;
            line-height: 50px;
            margin-left: 30px;
          }
        }
      }
      .page-container {
        position: absolute;
        right: 20px;
        top: 0px;
      }
    }
    .search-con {
      display: flex;
      .post-list {
        width: 100%;
        .post-box {
          height: 200px;
          display: flex;
          font-size: 1.3rem;
          margin: 10px auto;
          cursor: pointer;
          .part2 {
            width: 250px;
            height: 200px;
            div {
              height: 100%;
              width: 100%;
              background: no-repeat 50% / contain;
            }
            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
              background: #ccc;
            }
          }
          .part3 {
            flex: 2;
            margin-left: 30px;
            .title {
              font-size: 18px;
            }
            .addr {
              text-align: left;
              height: 50px;
              line-height: 50px;
              font-size: 14px;
              font-weight: 400;
              font-family: "Microsoft Yahei", "Simsun";
            }
            div {
              text-align: left;
              height: 30px;
              line-height: 30px;
            }
            .text {
              height: 12rem;
              font-size: 14px;
              font-weight: 400;
              font-family: "Microsoft Yahei", "Simsun";
              overflow-y: auto;
              // overflow: hidden;
              // display: -webkit-box;
              // -webkit-line-clamp: 4;
              // -webkit-box-orient: vertical;
              // text-overflow: ellipsis;
              // white-space: normal;
            }
          }
          .part4 {
            width: 555px;
            border-right: 1px solid #ccc;
            .title {
              text-align: center;
              height: 50px;
              line-height: 50px;
              border-bottom: 1px solid #ccc;
            }
            .four {
              height: 199px;
              overflow-y: auto;
              .four-title {
                text-align: left;
                margin-left: 5px;
                margin-bottom: 10px;
              }
              .con {
                text-align: left;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
    .page-container {
      text-align: right;
      margin-top: 5px;
    }
  }
}
</style>
